import { Route } from '@angular/router';
// import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'inventory' },
    // {path: 'dashboard', pathMatch : 'full', redirectTo: 'dashboard/apps/ecommerce'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for guests
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            // {
            //     path: 'confirmation-required',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/confirmation-required/confirmation-required.routes'
            //         ),
            // },
            // {
            //     path: 'forgot-password',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/forgot-password/forgot-password.routes'
            //         ),
            // },
            // {
            //     path: 'reset-password',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/reset-password/reset-password.routes'
            //         ),
            // },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
                canActivate: [NoAuthGuard],
            },
            // {
            //     path: 'sign-up',
            //     loadChildren: () =>
            //         import('app/modules/auth/sign-up/sign-up.routes'),
            // },
        ],
    },

    // Auth routes for authenticated users
    // {
    //     path: 'home',
    //     // canActivate: [AuthGuard],
    //     // canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty',
    //     },
    //     children: [
    //         {
    //             path: '',
    //             loadChildren: () =>
    //                 import('app/modules/landing/home/home.routes'),
    //         },
    //         {
    //             path: 'sign-out',
    //             loadChildren: () =>
    //                 import('app/modules/auth/sign-out/sign-out.routes'),
    //         },
    //         {
    //             path: 'unlock-session',
    //             loadChildren: () =>
    //                 import(
    //                     'app/modules/auth/unlock-session/unlock-session.routes'
    //                 ),
    //         },
    //     ],
    // },
    {
        path: 'inventory',
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('app/modules/admin/inventory/ecommerce.routes'),
        data: {
            expectedRole: ['66fbb51140efed7cfaf3b396', '67174b61fa21d8bb5b5e739c', '66fbb54940efed7cfaf3b39a'] // Admin Role, SubAdmin, Frontdesk
        }
    },
    {
        path: 'users',
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        loadChildren: () => import('app/modules/admin/users/users.routes'),
        data: {
            expectedRole: ['66fbb51140efed7cfaf3b396', '67174b61fa21d8bb5b5e739c'] // Admin Role Subadmin
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'booking',
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        loadChildren: () => import('app/modules/admin/booking/booking.routes'),
        data: {
            expectedRole: ['66fbb51140efed7cfaf3b396', '67174b61fa21d8bb5b5e739c', '66fbb54940efed7cfaf3b39a'] // Admin Role Subadmin Frontdesk
        },
        canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
    },
    {
        path: '',
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        loadChildren: () => import('app/modules/admin/data-management/data-management.routes'),
        data: {
            expectedRole: ['66fbb51140efed7cfaf3b396', '67174b61fa21d8bb5b5e739c'] // Admin Role Subadmin Frontdesk
        },
        canActivate: [AuthGuard],
    },
    // 404 & Catch all
    {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
            import(
                'app/modules/error-404/error-404.routes'
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];
