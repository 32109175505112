import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);
    const _jwtHelper = inject(JwtHelperService);

    // Check the authentication status
    const accessToken = localStorage.getItem('accessToken');
    // const userRole = localStorage.getItem('userRole');

    if (accessToken) {
        const decodedToken: any = _jwtHelper.decodeToken(accessToken)
        const userRole = decodedToken.roleId
        if(userRole == '66fbb51140efed7cfaf3b396'){ //Admin Role
            router.navigate(['/inventory/list']);
        } else if (userRole === '66fbb54940efed7cfaf3b39a') { // Front desk Role
            router.navigate(['/booking/list']);
        } else if (userRole === '66fbb55b40efed7cfaf3b39c') { // Collection Partner Role
            router.navigate(['/404-not-found']);
        } else if (userRole === '66fbb52e40efed7cfaf3b398') { // Customer Role
            router.navigate(['/404-not-found']);
        }
        return of(false);
    }

    // Allow the access
    return of(true);
};
