import { NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Navigation, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { User } from 'app/core/user/user.types';
// import { SearchComponent } from 'app/layout/common/search/search.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        RouterOutlet,
        FuseVerticalNavigationComponent,
        MatIconModule,
        // SearchComponent,
        NgIf,
    ],
})
export class EmptyLayoutComponent implements OnDestroy, OnInit {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    menuData: FuseNavigationItem[] = [
        {
            id: 'home',
            title: 'Home',
            type: 'basic',
            icon: 'mat_outline:home',
            link: '/home',
        },
        {
            id: 'sign-in',
            title: 'Log In',
            type: 'basic',
            icon: 'mat_outline:login',
            link: '/sign-in',
        },
        {
            id: 'sign-up',
            title: 'Sign Up',
            type: 'basic',
            icon: 'mat_outline:login',
            link: '/sign-up',
        },
        {
            type: 'spacer',
        },
    ];

    /**
     * Constructor
     */
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private el: ElementRef,
        private renderer: Renderer2
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        // Initial screen size check
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }

        const containerElement = this.el.nativeElement.querySelector(
            '.router-outlet-container'
        );
        if (containerElement) {
            // Temporarily set width to 90%
            this.renderer.setStyle(containerElement, 'width', '99%');
            // Force a reflow and then set width back to 100%
            window.dispatchEvent(new Event('resize'));
            setTimeout(() => {
                this.renderer.setStyle(containerElement, 'width', '100%');
                window.dispatchEvent(new Event('resize'));
            }, 0);
        }
    }
}
